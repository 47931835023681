.documents {
  background-color: #661520;
  padding: 10px 0px 30px;

  h3 {
    color: white;
    font-size: 3.5em;
    font-weight: 500;
    text-align: center;
    margin-bottom: 30px;
  }
  .boxContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: 0px auto 50px;
    float: none;

  }
  .box {
    height: 330px;
    width: 330px;
    background-color: white;
    padding: 0px 10px;
    margin-bottom:10px;
    a {
      color: black;
      &:hover {
        span {
        text-decoration: underline;
        }
      }
    }
  }

  h2 {
    font-weight: 500;
    text-align: center;
    margin-bottom: 0px;
  }

  p {
    margin-top: 5px;
    font-size: 1.5em;
  }
  .link {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    float:right;
    //cursor: pointer;
  }
}
