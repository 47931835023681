.mentalHealth {
  h1, h2 {
    width: 100%;
    text-align:center;
  }
  h1 {
    font-family: 'Abril Fatface';
    margin-bottom:10px;
  }
  h2 {
    font-family: "Cabin", sans-serif;
    font-size: 36px;
    font-weight: 300;
    margin-top:10px;
  }
}
