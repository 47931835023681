.caseHero {
  p {
    font-size: 24px;
    margin-top: 0px;
  }
  h1 {
    text-align: center;
  }
  .image {
    //box-shadow: 10px 5px 5px rgba(0,0,0,.5);
  }
}
