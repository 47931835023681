
/*
.actions {
  display: block;
  width: 200px;
  margin: auto;
  float: none;
}
*/

.formContainer {
  //width: 100%;
  width: 350px;
  //margin: 0px auto;
  //float: none;
  background-color: #f9f9f9;
  padding: 20px;
  h2 {
    font-size: 32px;
  }
}

.hs-button {
  width: 100% !important;
}
