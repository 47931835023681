.caseModule {
  padding: 60px 0px 50px;
  .moduleContainer {
    float: none;
    margin: 0px auto;
    position:relative;
  }
  .circle {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    position: absolute;
  }
  hr {
    //margin-top: 100px;
  }
  .moduleText {
    position:absolute;
    //width:615px;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 10px 20px;
  }
  p {
    font-size: 22px;
    margin-top: 5px;
  }
  h5 {
    margin-top: 0px;
  }

}
