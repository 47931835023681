.sustainability {
  header {
    position:fixed;
    &.background {
      background-color:white;
      border-bottom:1px solid black;
    }
  }
  .heroContainer {
    &:last-child {
      display:table;
      h1 {
        font-family: 'Vast Shadow', cursive;
        display:table-cell;
        text-align: zcenter;
        float:left;
      }
      h2 {
        width:100%;
        font-size:36px;
        text-align:center;
        float:left;
        font-style: italic;
        font-family: 'Lato', sans-serif;
        font-weight:400
      }
    }
  }
}
