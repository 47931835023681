.writing {
  background-color: #FEFEFE;
  padding: 10px 0px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  button {
    margin: 0px auto;
    float: none;
  }

  .boxContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: 0px auto;
    float: none;
  }

  h2 {
    color: #58585b;
    font-weight: 300;
    text-align:center;
    font-style: italic;
  }

  .box {
    //width: 225px;

    .boxThumbnail {
      width:225px;
      height:225px;
      background-size: cover;
      background-repeat:   no-repeat;
      background-position: center center;
      border: 1px solid black;
    }

    p {
      color:#58585b;
      font-size: 24px;
      font-weight:300;
      text-align:center;
      display:block;
      font-family: 'Cabin', sans-serif;
    }
  }
}
