/**
.formContainer {
  width: 100%;
  max-width: 1260px;
  margin: 0px auto;
  float: none;
  background-color: #f9f9f9;
  padding: 20px;
  h2 {
    font-size: 32px;
  }
}
*/
.post {
  h1, h2 {
    text-align: center;
    line-height: 1em;
  }

  h2 {
    font-size: 1.5em;
    line-height: 1.53em;
  }

  time {
    font-size: 32px;
  }

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
}
