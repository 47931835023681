.hero {
  align-items: center;
  display: flex;
  justify-content: center;

  div {
    text-align:center;
  }

  h1 {
    margin-bottom: 0px;
    margin-top: 10px;
  }

  h2 {
    margin-top: 0px;
    margin-bottom: 20px;
  }

}
