.trailer {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(245,245,245,1) 50%);

  h2 {
    font-style: italic;
  }


}
