/* Color */
$dark-maroon: #661520;
$light-maroon: #8D1D28;
$dark-gold: #B28859;
$light-gold: #D3AE7A;
$gray-700: #2B353D;
$gray-600: #5D5F67;
$gray-500: #7D7F86;
$gray-400: #CFD2D6;
$gray-300: #DEDFE0;
$gray-200: #EFEFE0;
$gray-100: #F7F7F8;
$red: #D32F2F;
$light-red: #FFF4F5;
$green: #008040;
$light-green: #E1F2E6;
$yellow: #FFD000;
$light-yellow: #FFF6CC;

/* Type */
@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Fanwood+Text:ital@0;1&family=Rye&family=Pinyon+Script&family=Quattrocento+Sans:ital,wght@0,400;0,700;1,400&family=Rampart+One&family=Abril+Fatface&family=Vast+Shadow&?family=Lato:ital@1&family=Life+Savers:wght@800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=ABeeZee&family=Cinzel+Decorative&family=Lora&family=Rye&display=swap');

html, body {
  width: 100%;
  min-width: 415px;
}

body {
  font-size: 16px;
}

h1 {
  font-size: 3.5em;
  font-weight: 300;
  font-family: 'Fanwood Text', serif;
  color: $light-maroon;
}

h2 {
  font-size: 2em;
  font-family: 'Fanwood Text', serif;
}

h3 {
  font-size: 1.75em;
  font-family: 'Pinyon Script', cursive;
}

h4 {
  font-family: 'Cabin', sans-serif;
  font-size: 3em;
  font-weight: 300;
}

h5 {
  font-family: 'Fanwood Text', serif;
  font-size: 2.25em;
  font-weight: 300;
  margin-bottom: 5px;
}

p {
  font-family: 'Fanwood Text', serif;
  font-size: 1.5em;
}

strong {
  font-family: 'Cabin', sans-serif;
  font-size: 3em;
}

small, span {
  font-family: 'Fanwood Text', serif;
  font-size: 1.5em;
}

html,
body {
  height: 100%;
  font-family: sans-serif;
}

body {
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex: 1 0 auto;
  //padding: 10px;
}

button {
  width: 325px;
  height: 70px;
  background-color: $light-gold;
  color: white;
  text-transform: uppercase;
  font-size: 1.75em;
  font-family: 'Cabin', sans-serif;
  border: none;
  box-shadow: 2px 3px 3px rgba(0,0,0,.5);
  cursor: pointer;
}
