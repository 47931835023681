.cases {
  h5 {
    text-transform: uppercase;
    text-align:center;
    //margin-bottom: 40px;
  }

  .caseContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    a {
      display: block;
      //margin: 0px 10%;
    }
    & > div {
      display: flex;
      padding-left: 1%;
      gap: 5%;
    }
    //padding: 0px 100px 0px;
    //.subcontainer {
      //display: flex;
      //justify-content: space-between;
      //align-items: center;
    .case {
      background-repeat: no-repeat;
      background-size:contain;
      background-repeat: no-repeat;
      background-size:cover;
      box-shadow: 10px 5px 5px rgba(0,0,0,.5);
      background-position: center center;
    }
    //}


  }
}
